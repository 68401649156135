<template>
  <a-row :gutter='25'>
    <a-col :xxl='8' :lg='8' :xs='24'>
      <a-form-item ref="validations.characterType" label="Tipo de carácter *" name="validations.characterType" class="title-normal" >
        <a-select
          size="large"
          name="validations.characterType"
          class="sDash_fullwidth-select title-normal form-input"
          v-model:value="validations.characterType"
          v-on:change="onCharacterTypeChange"
        >
          <template v-slot:suffixIcon>
            <font-awesome-icon
              :icon="faSort"
              size="1x"
              class="icon"
            />
          </template>
          <a-select-option class="title-normal" v-for="item in charactersTypes" :key="item" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <a-col :xxl='8' :lg='8' :xs='24'>
      <a-form-item ref="validations.validationType" label="Tipo de validación *" name="validations.validationType" class="title-normal" >
        <a-select
          size="large"
          name="validations.validationType"
          class="sDash_fullwidth-select title-normal form-input"
          v-model:value="validations.validationType"
        >
          <template v-slot:suffixIcon>
            <font-awesome-icon
              :icon="faSort"
              size="1x"
              class="icon"
            />
          </template>
          <a-select-option class="title-normal" v-for="item in validationTypeOptions" :key="item" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <template v-if="validations.validationType === 'limits'">
      <a-col :xxl='8' :lg='8' :xs='24'>
        <a-form-item
          ref="validations.min"
          label="Mínimo de caracteres"
          name="validations.min"
          class="title-normal"
          :validateStatus="errors && errors.validations && errors.validations?.min ? 'error' : ''"
          :help="errors && errors.validations && errors.validations?.min ? 'El valor debe ser mayor a 0 y menor al número máximo' : ''"
        >
          <a-input v-model:value="validations.min" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.min" class="form-input" />
        </a-form-item>
      </a-col>
      <a-col :xxl='8' :lg='8' :xs='24'>
        <a-form-item
          ref="validations.max"
          label="Máximo de caracteres"
          name="validations.max"
          class="title-normal"
          :validateStatus="errors && errors.validations && errors.validations?.min ? 'error' : ''"
          :help="errors && errors.validations && errors.validations?.min ? 'El valor debe ser mayor a 0 y mayor al número mínimo' : ''"
        >
          <a-input v-model:value="validations.max" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.max" class="form-input" />
        </a-form-item>
      </a-col>
      <a-col :xxl='8' :lg='8' :xs='24' v-if="validations.characterType == 'number'">
        <a-form-item ref="validations.length" label="Número exacto de carácteres" name="validations.length" class="title-normal" >
          <a-input v-model:value="validations.length" v-on:keydown="onKeyDown" name="validations.length" class="form-input" />
        </a-form-item>
      </a-col>
    </template>
    <a-col :xxl='8' :lg='8' :xs='24' v-if="validations.validationType === 'predet'">
      <a-form-item
        ref="validations.format"
        label="Tipo de carácter *"
        name="validations.format"
        class="title-normal"
        :validateStatus="errors && errors.validations && errors.validations?.format ? 'error' : ''"
        :help="errors && errors.validations && errors.validations?.format ? 'Este campo es requerido' : ''"
      >
        <a-select
          size="large"
          name="format"
          class="sDash_fullwidth-select title-normal form-input"
          v-model:value="validations.format"
        >
          <template v-slot:suffixIcon>
            <font-awesome-icon
              :icon="faSort"
              size="1x"
              class="icon"
            />
          </template>
          <a-select-option class="title-normal" v-for="item in formatTypes" :key="item" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { faSort } from '@fortawesome/free-solid-svg-icons';

const textValidationTypes = [
  {value: 'predet', label: 'Formato predeterminado'},
  {value: 'limits', label: 'Límites'},
]

const numberValidationTypes = [
  {value: 'limits', label: 'Límites'},
  {value: 'decimal', label: 'Decimal'},
]

const formatTypes = [
  {value: 'email', label: 'Correo electrónico'},
  {value: 'url', label: 'URL'},
]

const ShortAnswerValidations = {
  name: 'ShortAnswerValidations',
  components: { },
  props: {
    validations: Object,
    errors: Object
  },
  data() {
    return { validationTypeOptions: this.validations.characterType == 'number' ? numberValidationTypes : textValidationTypes};
  },
  setup() {
    const charactersTypes = [
      {value: 'text', label: 'Texto'},
      {value: 'number', label: 'Númerico'},
    ]

    return {
      charactersTypes,
      formatTypes,
      faSort,
    };
  },
  methods: {
    onKeyDown: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(!((charCode >= 96 && charCode <= 107) || (charCode >= 48 && charCode <= 57) || (charCode >= 37 && charCode <= 40) || charCode === 8 || charCode === 46)) {
        evt.preventDefault();
      } else if (evt.target.name === 'validations.length') {
        this.validations.min = '';
        this.validations.max = '';
      }
      return true;
    },
    onCharacterTypeChange: function (evt) {
      this.validationTypeOptions = (evt === 'text') ? textValidationTypes : numberValidationTypes;
      this.validations.validationType = 'limits'
    }
  }
};

export default ShortAnswerValidations;
</script>
<style scoped>
.icon {
  color: #3FA7E1;
}
.form-input {
  border-radius: 20px;
}
</style>
<style>
.form-input .ant-select-selector {
  border-radius: 20px !important;
}
</style>
