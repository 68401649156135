<template>
  <FormValidationWrap>
    <VerticalFormStyleWrap>
      <div
        class="QuestionForm"
        :class="[ question.id == editingQuestion.id ? 'editing-or-dragg' : '', ]"
        @click="questionClick"
      >
        <question-header
          v-if='question.id == editingQuestion.id'
          @add-question="addQuestion"
          @delete-question="deleteQuestion"
          @copy-question="copyQuestion"
          @add-section="addSection"
          @toggle-required="toggleRequired"
          @toggle-go-to='toggleGoTo'
          :required="question.validations.required"
          :can-delete='canDelete'
          :question-type='question.type'
          :dynamic-sections='question.goto'
        />
        <a-row :gutter='25' style='margin-bottom: 1rem'>
          <a-col :xxl='15' :lg='15' :xs='24'>
            <a-form-item
              ref="title"
              label="Título de la pregunta *"
              name="title"
              class="title-normal"
              :validateStatus="errors && errors?.title ? 'error' : ''"
              :help="errors && errors?.title ? 'Este campo es requerido' : ''"
            >
              <a-input v-model:value="question.title" class="form-input" />
            </a-form-item>
          </a-col>
          <a-col :xxl='9' :lg='9' :xs='24'>
            <a-form-item ref="type" label="Tipo de pregunta *" name="type" class="title-normal" >
              <a-select
                size="large"
                name="type"
                class="sDash_fullwidth-select title-normal form-input"
                v-model:value="question.type"
              >
                <template v-slot:suffixIcon>
                  <font-awesome-icon
                    :icon="faSort"
                    size="1x"
                    class="icon"
                  />
                </template>
                <a-select-option class="title-normal" v-for="item in questionsTypesDic" :key="item" :value="item.value">
                  <span>
                    <img
                      :src="require(`@/static/img_events/${item.icon}`)"
                      alt="icon" width="16" height="16" style="margin-right: 0.8rem;"
                    />
                    {{ item.label }}
                  </span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter='25' v-if='question.type != questionTypes.cct'>
          <a-col :xxl='24' :lg='24' :xs='24'>
            <a-form-item
              ref="description"
              label="Descripción de la pregunta"
              name="description"
              class="title-normal"
            >
              <a-textarea v-model:value="question.description" class="form-input" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter='25' v-if="question.type === questionTypes.date">
          <a-col :xxl='8' :lg='8' :xs='24'>
            <a-form-item ref="" label="Fecha" class="title-normal form-font">
              <a-input type="date" class="form-input form-input-disabled input-addon input-field" disabled>
                <template #addonAfter style="background-color: #f5f5f5">
                  <img
                    :src="require(`@/static/img_events/SEJ_Eventos_fecha.svg`)"
                    alt="logo" width="14" height="14"
                  />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter='25' v-if="question.type === questionTypes.time">
          <a-col :xxl='8' :lg='8' :xs='24'>
            <a-form-item ref="" label="Hora" class="title-normal form-font">
              <a-input type="time" class="form-input input-field" disabled/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter='25' v-if="question.type === questionTypes.file">
          <a-form-item ref="files " name="files" class="title-normal">
            <button class="btn-add-outlined disabled" disabled>
              <img
                :src="require(`@/static/img_events/SEJ_Eventos_carga de archivos.svg`)"
                alt="logo"
                width="16"
                height="16"
                style="margin-right: 0.6rem"
              />
              <span class="title-normal"> Adjuntar archivos </span>
            </button>
          </a-form-item>
        </a-row>

        <a-row :gutter='25' v-if="question.type === questionTypes.cct">
          <a-col :xxl='15' :lg='15' :xs='24'>
            <a-form-item
              ref=""
              label="Selecciona los datos que requieras del CCT *"
              name="cct"
              class="form-font mx-3"
              :validateStatus="errors && errors.cct ? 'error' : ''"
              :help="errors && errors.cct ? 'Selecciona al menos una opción' : ''"
            >
              <a-select
                size="large"
                name="cct"
                mode='multiple'
                class="title-normal"
                v-model:value="question.cct"
                style="width: 100%"
                placeholder="Seleccione opciones"

              >
                <a-select-option class="title-normal" v-for="item in cctFieldsDic" :key="item" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <question-options
          v-if="optionQuestionTypes.includes(question.type)"
          :options="question.options"
          :questionType="question.type"
          :errors="errors ? errors : {}"
          :sections="sections"
          :gotosec = "question.goto"
        />
        <question-validations
          v-if="validationQuestionTypes.includes(question.type)"
          :validations="question.validations"
          :questionType="question.type"
          :errors="errors ? errors : {}"

        />
        <text-matrix
          v-if='question.type === questionTypes.matrixTxt'
          :matrix-options = "question.matrixOptions"
          :errors = "errors ? errors : {}"
          :validations="question.matrixValidations"
        />
        <matrix-txt-validations
          v-if='question.type === questionTypes.matrixTxt'
          :options = "question.matrixOptions"
          :validations="question.matrixValidations"
          :errors="errors ? errors : {}"
        />
      </div>
    </VerticalFormStyleWrap>
  </FormValidationWrap>
</template>

<script>
import { Input } from 'ant-design-vue';
const { TextArea } = Input;
import * as questionTypes from '@/constants/QuestionTypes.js';
import { ref, toRef } from 'vue';
import {FormValidationWrap, VerticalFormStyleWrap} from './Style';
import QuestionHeader from './QuestionHeader.vue';
import QuestionValidations from './QuestionValidations.vue';
import QuestionOptions from './QuestionOptions.vue';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import {cctFieldsDic} from '@/constants/CCTFieldsDic.js';

import TextMatrix from './TextMatrix';
import MatrixTxtValidations from '@/view/create-event/overview/create/DynamicForm/FormMaker/validations/MatrixTxtValidations';
const Question = {
  name: 'QuestionForm',
  components: { Input, TextArea, FormValidationWrap, VerticalFormStyleWrap, QuestionHeader, QuestionValidations,
    QuestionOptions, TextMatrix, MatrixTxtValidations },
  props: {
    getQuestionForm: null,
    addQuestion: Function,
    indexSection: Number,
    indexQuestion: Number,
    editingQuestion: Object,
    errors: Object,
    sections: Array,
    question: {
      type: Object,
      default() {
        return {}
      }
    },
    form: Object,
    canDelete: Boolean,
  },
  emits:[
    "add-question",
    "delete-question",
    "copy-question",
    "add-section",
    "toggle-required",
    "set-editing-question",
    "toggle-go-to"
  ],


  async setup(props, {emit}) {
    const question = toRef(props, 'question');

    const questionsTypesDic = [
      { value: questionTypes.shortAnswer, label: 'Respuesta breve', icon: 'SEJ_Eventos_texto corto.svg' },
      { value: questionTypes.largeAnswer, label: 'Respuesta larga', icon: 'SEJ_Eventos_texto largo.svg' },
      { value: questionTypes.multipleChoice, label: 'Opción multiple', icon: 'SEJ_Eventos_opciones.svg' },
      { value: questionTypes.cbx, label: 'Casillas de verificación', icon: 'SEJ_Eventos_casillas de verificacion.svg' },
      { value: questionTypes.dropDown, label: 'Lista desplegable', icon: 'SEJ_Eventos_lista desplegable.svg' },
      { value: questionTypes.date, label: 'Fecha', icon: 'SEJ_Eventos_fecha.svg' },
      { value: questionTypes.time, label: 'Hora', icon: 'SEJ_Eventos_hora.svg' },
      { value: questionTypes.file, label: 'Carga de archivos', icon: 'SEJ_Eventos_carga de archivos.svg' },
      // { value: questionTypes.matrixTxt, label: 'Cuadrícula de respuesta de texto ', icon: 'zmdi-apps' },
      { value: questionTypes.cct, label: 'CCT', icon: 'Mi muro_Formularios_CCT.svg' },
      { value: questionTypes.matrixTxt, label: 'Cuadrícula de respuesta de texto ', icon: 'SEJ_Eventos_cuadricula de respuesta.svg' },
    ]
    const charactersTypes = [
      {value: 'text', label: 'Texto'},
      {value: 'number', label: 'Numerico'},
      {value: 'decimal', label: 'Decimal'},
    ]

    const addQuestion = ()=>{
      emit('add-question', props.indexSection);
    }
    const deleteQuestion = ()=>
    {
      emit('delete-question', props.indexSection, props.indexQuestion);
    }
    const copyQuestion = ()=>{
      emit('copy-question', props.indexSection, props.indexQuestion);
    }

    const addSection = ()=>{
      emit('add-section');
    }

    const questionClick = () => {
      emit('set-editing-question', props.question.id)
    }
    const toggleRequired = ()=>{
      emit('toggle-required', props.indexSection, props.indexQuestion );
    }
    const toggleGoTo = ()=>{
      emit('toggle-go-to', props.indexSection, props.indexQuestion );
    }
    const formRef = ref();

    const optionQuestionTypes = [questionTypes.multipleChoice, questionTypes.cbx, questionTypes.dropDown];
    const validationQuestionTypes = [questionTypes.shortAnswer, questionTypes.largeAnswer];

    return {
      formRef,
      questionsTypesDic,
      charactersTypes,
      faSort,
      question,
      addQuestion,
      deleteQuestion,
      copyQuestion,
      addSection,
      toggleRequired,
      questionClick,
      questionTypes,
      optionQuestionTypes,
      validationQuestionTypes,
      cctFieldsDic,
      toggleGoTo
    };
  },
};

export default Question;
</script>
<style scoped>
.QuestionForm{
  border: 1px solid #D3D3D3;
  border-radius: 18px;
  opacity: 1;
  padding: 28px 30px;
  margin-top: 1.2rem;
}

.icon {
  color: #3FA7E1;
}
.form-input {
  border-radius: 20px;
}
.editing-or-dragg {
  border-left: 8px solid #51AF2C;
  border-radius: 18px;
}
</style>
<style>
.form-input .ant-select-selector {
  border-radius: 20px !important;
}
.form-input-disabled span.ant-input-group-addon {
  background-color: #f5f5f5;
}
</style>
