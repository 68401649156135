<template>
  <short-answer-validations v-if="questionType === questionTypes.shortAnswer" :validations="validations" :errors="errors" />
  <large-answer-validations v-if="questionType === questionTypes.largeAnswer" :validations="validations" :errors="errors" />
</template>

<script>
import VueTypes from 'vue-types';
import ShortAnswerValidations from './validations/ShortAnswerValidations.vue';
import LargeAnswerValidations from './validations/LargeAnswerValidations.vue';
import * as questionTypes from '@/constants/QuestionTypes.js';

const QuestionValidations = {
  name: 'QuestionValidations',
  components: { ShortAnswerValidations, LargeAnswerValidations },
  props: {
    validations: Object,
    questionType: VueTypes.string,
    errors: Object
  },
  setup() {
    return { questionTypes };
  }
};

export default QuestionValidations;
</script>
<style scoped>
</style>
