<template>
  <h3>Opciones</h3>
  <h4 v-if="errors && errors?.noOptions" class="error">Ingrese por lo menos una opción</h4>
  <multiple-choice-options v-if="questionType === questionTypes.multipleChoice" :options="options" :errors="errors" :sections = "sections" :dynamicSection="gotosec"/>
  <cbx-options v-if="questionType === questionTypes.cbx" :options="options" :errors="errors" />
  <dropdown-options v-if="questionType === questionTypes.dropDown" :options="options" :errors="errors" :sections="sections" :dynamicSection="gotosec"/>
</template>

<script>
import VueTypes from 'vue-types';
import MultipleChoiceOptions from './options/MultipleChoiceOptions.vue';
import CbxOptions from './options/CbxOptions.vue';
import DropdownOptions from './options/DropdownOptions.vue';
import * as questionTypes from '@/constants/QuestionTypes.js';


const QuestionOptions = {
  name: 'QuestionOptions',
  components: { MultipleChoiceOptions, CbxOptions, DropdownOptions },
  props: {
    options: Object,
    questionType: VueTypes.string,
    errors: Object,
    sections: Array,
    gotosec: VueTypes.bool,
  },
  setup() {
    return { questionTypes };
  }
};

export default QuestionOptions;
</script>
<style scoped>
  .error {
    color: #f5222d;
  }
</style>
