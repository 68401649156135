<template>
  <a-row :gutter='25'>
    <a-col :xxl='8' :lg='8' :xs='24'>
    <a-form-item
      ref="validations.min"
      label="Mínimo de caracteres"
      name="validations.min"
      class="title-normal"
      :validateStatus="errors && errors.validations && errors.validations?.min ? 'error' : ''"
      :help="errors && errors.validations && errors.validations?.min ? 'El valor debe ser mayor a 0 y menor al número máximo' : ''"
    >
        <a-input v-model:value="validations.min" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.min" class="form-input" />
    </a-form-item>
    </a-col>
    <a-col :xxl='8' :lg='8' :xs='24'>
    <a-form-item
      ref="validations.max"
      label="Máximo de caracteres"
      name="validations.max"
      class="title-normal"
      :validateStatus="errors && errors.validations && errors.validations?.max ? 'error' : ''"
      :help="errors && errors.validations && errors.validations?.max ? 'El valor debe ser mayor a 0 y mayor al número mínimo' : ''"
    >
        <a-input v-model:value="validations.max" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.max" class="form-input" />
    </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
const LargeAnswerValidations = {
  name: 'LargeAnswerValidations',
  components: { },
  props: {
    validations: Object
  },
  setup() {

    const rules = { };

    return {
      rules,
    };
  },
  methods: {
    onKeyDown: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(!((charCode >= 96 && charCode <= 107) || (charCode >= 48 && charCode <= 57) || (charCode >= 37 && charCode <= 40) || charCode === 8 || charCode === 46)) {
        evt.preventDefault();
      }
      return true;
    }
  }
};

export default LargeAnswerValidations;
</script>
<style scoped>
.icon {
  color: #3FA7E1;
}
.form-input {
  border-radius: 20px;
}
</style>
