<template>
  <template v-for='(col, colIndex) in options.cols' :key='col.id'>
    <a-col :xxl='24' :lg='24' :xs='24' v-for='(row, rowIndex) in options.rows' :key='`T_${col.id}_${row.id}`'>

      <a-row :gutter='25'>
        <p class='title-normal'>{{`Campo Columna ${colIndex+1} : Fila ${rowIndex+1} `}}</p>
      </a-row>
      <a-row :gutter='25'>
        <a-col :xxl='8' :lg='8' :xs='24'>
          <a-form-item :ref="validations.dataType"
                       label="Tipo *"
                       :name="`T_${col.id}_${row.id}.dataType`" class="title-normal" >
            <a-select
              size="large"
              :name="`T_${col.id}_${row.id}.dataType`"
              class="sDash_fullwidth-select title-normal form-input"
              v-model:value="validations[`T_${col.id}_${row.id}`].dataType"
            >
              <template v-slot:suffixIcon>
                <font-awesome-icon
                  :icon="faSort"
                  size="1x"
                  class="icon"
                />
              </template>
              <a-select-option class="title-normal" v-for="item in charactersTypes" :key="item" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <template v-if="validations[`T_${col.id}_${row.id}`].dataType === 'text'">
          <a-col :xxl='8' :lg='8' :xs='24'>
            <a-form-item :ref="validations[`T_${col.id}_${row.id}`].validationType" label="Validación *" :name="`T_${col.id}_${row.id}.validationType`" class="title-normal" >
              <a-select
                size="large"
                :name="validations[`T_${col.id}_${row.id}`].validationType"
                class="sDash_fullwidth-select title-normal form-input"
                v-model:value="validations[`T_${col.id}_${row.id}`].validationType"
              >
                <template v-slot:suffixIcon>
                  <font-awesome-icon
                    :icon="faSort"
                    size="1x"
                    class="icon"
                  />
                </template>
                <a-select-option class="title-normal" v-for="item in validationTypeOptions" :key="item" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <template v-if="validations[`T_${col.id}_${row.id}`].validationType === 'limits'">
            <a-col :xxl='4' :lg='4' :xs='24'>
              <a-form-item
                :ref="validations[`T_${col.id}_${row.id}`].min"
                label="Mín"
                :name="`T_${col.id}_${row.id}.min`"
                class="title-normal"
                :validateStatus="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.min ? 'error' : ''"
                :help="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.min ? 'El valor debe ser mayor a 0 y menor al número máximo' : ''"
              >
                <a-input v-model:value="validations[`T_${col.id}_${row.id}`].min" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.min" class="form-input" />
              </a-form-item>
            </a-col>
            <a-col :xxl='4' :lg='4' :xs='24'>
              <a-form-item
                :ref="`T_${col.id}_${row.id}.max`"
                label="Máx"
                :name="`T_${col.id}_${row.id}.max`"
                class="title-normal"
                :validateStatus="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.max ? 'error' : ''"
                :help="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.max ? 'El valor debe ser mayor a 0 y mayor al número mínimo' : ''"
              >
                <a-input v-model:value="validations[`T_${col.id}_${row.id}`].max" v-on:keydown="onKeyDown" :disabled="!!validations?.length" name="validations.max" class="form-input" />
              </a-form-item>
            </a-col>

          </template>
          <a-col :xxl='8' :lg='8' :xs='24' v-if="validations[`T_${col.id}_${row.id}`].validationType === 'predet'">
            <a-form-item
              :ref="validations[`T_${col.id}_${row.id}.format`]"
              label="Tipo de carácter *"
              :name="validations[`T_${col.id}_${row.id}.format`]"
              class="title-normal"
              :validateStatus="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.format ? 'error' : ''"
              :help="errors && errors.matrixValidations && errors.matrixValidations?.[`T_${col.id}_${row.id}`]?.format ? 'Este campo es requerido' : ''"
            >
              <a-select
                size="large"
                name="format"
                class="sDash_fullwidth-select title-normal form-input"
                v-model:value="validations[`T_${col.id}_${row.id}`].format"
              >
                <template v-slot:suffixIcon>
                  <font-awesome-icon
                    :icon="faSort"
                    size="1x"
                    class="icon"
                  />
                </template>
                <a-select-option class="title-normal" v-for="item in formatTypes" :key="item" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-col>
  </template>

</template>

<script>
import { faSort } from '@fortawesome/free-solid-svg-icons';

const textValidationTypes = [
  {value: 'predet', label: 'Formato predeterminado'},
  {value: 'limits', label: 'Límites'},
]

const numberValidationTypes = [
  {value: 'limits', label: 'Límites'},
  {value: 'decimal', label: 'Decimal'},
]

const formatTypes = [
  {value: 'email', label: 'Correo electrónico'},
  {value: 'url', label: 'URL'},
]

const MatrixTxtValidations = {
  name: 'MatrixTxtValidations',
  components: { },
  props: {
    validations: Object,
    errors: Object,
    options: Object
  },

  data() {
    return {
      validationTypeOptions: textValidationTypes,

    };
  },
  setup() {
    const charactersTypes = [
      {value: 'text', label: 'Texto'},
      {value: 'number', label: 'Númerico'},
    ]

    return {
      charactersTypes,
      formatTypes,
      faSort,
    };
  },
  methods: {
    onKeyDown: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if(!((charCode >= 96 && charCode <= 107) || (charCode >= 48 && charCode <= 57) || (charCode >= 37 && charCode <= 40) || charCode === 8 || charCode === 46)) {
        evt.preventDefault();
      } else if (evt.target.name === 'validations.length') {
        this.validations.min = '';
        this.validations.max = '';
      }
      return true;
    },
    onCharacterTypeChange: function (evt) {

      this.validationTypeOptions = (evt === 'text') ? textValidationTypes : numberValidationTypes;
      this.validations.validationType = 'limits'
    }
  }
};

export default MatrixTxtValidations;
</script>
<style scoped>
.icon {
  color: #3FA7E1;
}
.form-input {
  border-radius: 20px;
}
</style>
<style>
.form-input .ant-select-selector {
  border-radius: 20px !important;
}
</style>
