<template>
  <div>
    <a-row justify="end">
      <a-col :xxl="4" :lg="6" :md="6" :xs="12" class="text-right">
        <a-space size="middle">
          <img
            v-if='canDelete'
            :src="require(`@/static/img_events/SEJ_Eventos_eliminar_formulario.svg`)"
            alt="logo" width="16" height="16"
            style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
            class="pointer"
            @click="emit('delete-question')"
          />
          <img
            :src="require(`@/static/img_events/SEJ_Eventos_copiar.svg`)"
            alt="logo" width="16" height="16"
            style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
            class="pointer"
            @click="emit('copy-question')"
          />
        </a-space>
      </a-col>
       <a-col :xxl="8" :lg="8" :md="12" :xs="24" class="text-center">
        <a-form-item ref="required" name="required" class="title-normal" >
          <span>Obligatoria </span>
          <a-switch  v-model:checked="props.required" @click="emit('toggle-required')"/>
          
        </a-form-item>
      </a-col>
      <a-col :xxl="4" :lg="4" :md="6" :xs="12" class="text-right">
        <a-space size="middle">
          <img
            :src="require(`@/static/img_events/SEJ_Eventos_agregar sección.svg`)"
            alt="logo" width="16" height="16"
            style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
            class="pointer"
            @click="emit('add-section')"
          />
          <img
            :src="require(`@/static/img_events/SEJ_Eventos_agregar pregunta.svg`)"
            alt="logo" width="16" height="16"
            style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
            class="pointer"
            @click="emit('add-question')"
          />
          <img
            v-if='(questionType === questionTypes.dropDown || questionType === questionTypes.multipleChoice)'
            :src="require(`@/static/img_events/SEJ_eventos_Ir_a_seccion.svg`)"
            alt="logo" width="16" height="16"
            style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
            class="pointer"
            @click="emit('toggle-go-to')"
          />
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref } from 'vue';
import VueTypes from 'vue-types';
import * as questionTypes from '@/constants/QuestionTypes.js';

export default {
  name: 'QuestionHeader',
  props:{
    required: Boolean,
    questionType: VueTypes.string,
    dynamicSections: Boolean,
    canDelete: Boolean
  },
  components: {
  },
  emits:[
    "add-question",
    "delete-question",
    "copy-question",
    "add-section",
    "toggle-required",
    "toggle-go-to",
  ],
  async setup(props, {emit}) {

    const formRef = ref();

    return {
      formRef, 
      emit,
      props,
      questionTypes
    }
  },
};
</script>

<style scoped>
  .ant-switch-checked {
    background-color: #E8394D;
  }
  .pointer {
    cursor: pointer;
  }
</style>
