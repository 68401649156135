<template>
  <a-row :gutter='25' style='margin-bottom: 1rem' v-for="(option, index) in options" :key='option.id'>
    <a-col :xxl='1' :lg='1' :xs='1'>
      <a-form-item ref="content" :label="option.label" name="content" class="title-normal">
        <a-radio class="form-font" :checked="false"></a-radio>
      </a-form-item>
    </a-col>
    <a-col
      :xxl='dynamicSection ? 15 : 22'
      :lg='dynamicSection ? 15 : 22'
      :xs='dynamicSection ? 15 : 20'>
      <a-form-item
        ref="content"
        name="content"
        class="title-normal"
        :validateStatus="errors && errors?.options && errors?.options[index]?.content ? 'error' : ''"
        :help="errors && errors?.options && errors?.options[index]?.content ? 'Este campo es requerido' : ''"
      >
        <a-input size="small" v-model:value="option.content" class="form-input input-addon">
          <template #addonAfter>
            <img
              :src="require(`@/static/img_events/SEJ_Eventos_eliminar opcion.svg`)"
              alt="logo" width="14" height="14"
              class="pointer"
              @click="deleteOption(index)"
            />
          </template>
        </a-input>
      </a-form-item>
    </a-col>
    <a-col :xxl="8" :lg="8" :xs="24" v-if="dynamicSection">
      <a-form-item ref="goto" label="Ir a" name="goto" class="title-normal">
        <a-select
          size="large"
          name="goto"
          class="sDash_fullwidth-select title-normal form-input"
          v-model:value="option.goto"
        >
          <template v-slot:suffixIcon>
            <font-awesome-icon :icon="faSort" size="1x" class="icon" />
          </template>
          <a-select-option class="title-normal" :value="'next'">
            Ir a la siguiente sección
          </a-select-option>
          <a-select-option class="title-normal" v-for="(sec, secIndex) in sections" :key="sec.id" :value="sec.id">
            <span>
              {{ `Ir a la sección ${secIndex+1} (${!!sec.title ? sec.title : 'Sección sin título'})` }}
            </span>
          </a-select-option>
          <a-select-option class="title-normal" :value="'finish'">
            <span>
              Enviar Formulario
            </span>
          </a-select-option>

        </a-select>
      </a-form-item>
    </a-col>
  </a-row>
  <a-row>
    <a-col :xxl='24' :lg='24' :xs='24'>
      <a-button class="btn-fill-add" @click="addOption()">Agregar otra opción</a-button> O <span class="text-primary pointer" @click="addOption('Otro')">Agregar "otro"</span>
    </a-col>
  </a-row>
</template>

<script>

import VueTypes from 'vue-types';

const MultipleChoiceOptions = {
  name: 'MultipleChoiceOptions',
  props: {
    options: Object,
    errors: Object,
    dynamicSection: VueTypes.bool,
    sections: Array,
  },
  methods: {
    generateUUID() {
      let d = new Date().getTime();
      let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    addOption(content = '') {
      this.options.push({id: `O${this.generateUUID()}`, content, label: '', goto:'next'});
    },
    deleteOption(index) {
      this.options.splice(index, 1);
    }
  }
};

export default MultipleChoiceOptions;
</script>
<style scoped>
  input {
    border-radius: 20px;
  }
  .form-input {
    border-radius: 20px;
  }
  .btn-fill-add{
    border: 1px solid #e70e4c;
    color: #ffffff;
    padding: 5px 15px 5px 15px;
    background: #e70e4c;
    border-radius: 24px;
  }
  .text-primary {
    color: #e70e4c;
    font-family: 'Nutmeg';
  }
  .pointer {
    cursor: pointer;
  }
</style>
<style>
  .form-input  input {
    border-radius: 20px;
  }

  .input-addon input {
    border-right: 0;
  }

  .form-input span.ant-input-group-addon {
    background: transparent;
    border-radius: 0 20px 20px 0;
  }

</style>
