<template>
  <a-row :gutter='25' style='margin-bottom: 1rem' v-for="(option, index) in options" :key='option.id'>
    <a-col :xxl='2' :lg='2' :xs='2'>
      <a-form-item ref="content" :label="option.label" name="content" class="title-normal">
        <a-checkbox class="form-font" :checked="false"></a-checkbox>
      </a-form-item>
    </a-col>
    <a-col :xxl='22' :lg='22' :xs='20'>
      <a-form-item
        ref="content"
        name="content"
        class="title-normal"
        :validateStatus="errors && errors?.options && errors?.options[index]?.content ? 'error' : ''"
        :help="errors && errors?.options && errors?.options[index]?.content ? 'Este campo es requerido' : ''"
      >
        <a-input size="small" v-model:value="option.content" class="form-input input-addon">
          <template #addonAfter>
            <img
              :src="require(`@/static/img_events/SEJ_Eventos_eliminar opcion.svg`)"
              alt="logo" width="14" height="14"
              class="pointer"
              @click="deleteOption(index)"
            />
          </template>
        </a-input>
      </a-form-item>
    </a-col>
  </a-row>
  <a-row>
    <a-col :xxl='24' :lg='24' :xs='24'>
      <a-button class="btn-fill-add" @click="addOption()">Agregar otra opción</a-button> O <span class="text-primary pointer" @click="addOption('Otro')">Agregar "otro"</span>
    </a-col>
  </a-row>
</template>

<script>

const CbxOptions = {
  name: 'CbxOptions',
  props: {
    options: Object,
    errors: Object
  },
  methods: {
    generateUUID() {
      let d = new Date().getTime();
      let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    addOption(content = '') {
      this.options.push({id: `O${this.generateUUID()}`, content, label: ''});
    },
    deleteOption(index) {
      this.options.splice(index, 1);
    }
  }
};

export default CbxOptions;
</script>
<style scoped>
  input {
    border-radius: 20px;
  }
  .form-input {
    border-radius: 20px;
  }
  .btn-fill-add{
    border: 1px solid #e70e4c;
    color: #ffffff;
    padding: 5px 15px 5px 15px;
    background: #e70e4c;
    border-radius: 24px;
  }
  .text-primary {
    color: #e70e4c;
    font-family: 'Nutmeg';
  }
  .pointer {
    cursor: pointer;
  }
</style>
<style>
  .form-input  input {
    border-radius: 20px;
  }

  .input-addon input {
    border-radius: 20px 0 0 20px !important;
  }

  .form-input span.ant-input-group-addon {
    background: transparent;
    border-radius: 0 20px 20px 0;
  }

</style>
