<template>
  <div class='dynamicForm form-card' style="margin-left: -15px; margin-right: -15px;padding: 2rem;">
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <a-form
          name='dynamicForm'
          :model='form'
          layout='vertical'
        >
          <h3 class='form-font title title-normal' style="margin-left: 1rem;">Formulario dinámico</h3>
          <div v-if='eventForm.hasDynamicForm'>
            <div class='dynamicForm__sections' v-for='(section, indexSection) in form.sections' :key='section.id'>
              <a-row v-if='form.sections.length>1' :gutter='25' class='section--number'>
                  <span >{{`Sección ${indexSection+1} de ${form.sections.length}`}}</span>
              </a-row>
              <div
                class='section--info'
                :class="[ section.id == editingQuestion.id ? 'editing-or-dragg' : '', form.sections.length>1 ? 'section--info--number' : '' ]"
                @click="setEditingQuestion(section.id)"
                style='margin-top: 0rem; background: white; '
              >
                <a-row v-if='form.sections.length>1' class='section--options'>
                  <a-space size="middle">
                    <img
                      :src="require(`@/static/img_events/SEJ_Eventos_eliminar_formulario.svg`)"
                      alt="logo" width="16" height="16"
                      style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
                      class="pointer"
                      @click='handleDeleteSectionModal(indexSection)'
                    />
                    <img
                      :src="require(`@/static/img_events/SEJ_Eventos_copiar.svg`)"
                      alt="logo" width="16" height="16"
                      style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
                      class="pointer"
                      @click='copySection(indexSection)'
                    />
                    <span>
                      </span>
                      <img
                        :src="require(`@/static/img_events/SEJ_Eventos_agregar pregunta.svg`)"
                        alt="logo" width="16" height="16"
                        style="margin-right: 0.8rem; margin-bottom: 0.5rem;"
                        class="pointer"
                        @click='addSection'
                      />
                    </a-space>
                </a-row>

                <a-row  :gutter='25' style='margin-bottom: 1rem'>
                  <a-col :xxl='24' :lg='24' :xs='24'>
                    <a-form-item
                      ref="title"
                      :label="form.sections.length>1 && indexSection != 0  ? 'Titulo de la sección *': 'Título del formulario  *' "
                      name="title"
                      class="title-normal"
                      :validateStatus="formState.errors?.sections[indexSection]?.title ? 'error' : ''"
                      :help="formState.errors?.sections[indexSection]?.title ? 'Este campo es requerido' : ''"
                    >
                      <a-input v-model:value="section.title" class="input-field" :disabled="indexSection == 0"/>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter='25'>
                  <a-col :xxl='24' :lg='24' :xs='24'>
                    <a-form-item 
                      ref="description"
                      :label="form.sections.length>1 && indexSection != 0  ? 'Descripción de la sección *': 'Descripción del formulario'
                      " name="description"
                      class="title-normal"
                      :validateStatus="formState.errors.sections[indexSection]?.description ? 'error' : ''"
                      :help="formState.errors.sections[indexSection]?.description ? 'Este campo es requerido' : ''"
                    >
                      <a-textarea v-model:value="section.description" class="input-field" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>

              <h2 class='form-subtitle' :v-if="form.sections.length>1 && indexSection != 0">
                Preguntas del formulario
              </h2>

              <draggable v-model="section.questions">
                <template #item="{ question, index }">
                  <div class='section__questions'>
                    <Question
                      :can-delete='section.questions.length>1'
                      :data='question'
                      :errors='formState.errors.sections[indexSection] && formState.errors.sections[indexSection].questions ? formState.errors.sections[indexSection].questions[index] : {}'
                      :question='section.questions[index]'
                      :indexSection='indexSection'
                      :indexQuestion='index'
                      :form='form'
                      :editingQuestion="editingQuestion"
                      :sections="eventForm.dynamicForm.sections"
                      @add-question="addQuestion"
                      @delete-question="deleteQuestion"
                      @copy-question="copyQuestion"
                      @add-section="addSection"
                      @set-editing-question="setEditingQuestion"
                      @toggle-required= "toggleRequired"
                      @toggle-go-to= "toggleGoTo"
                    />
                  </div>
                </template>
              </draggable>

            </div>

          </div>
          <div v-else>
            <button class='btn-add-form title-normal' @click='handleClickAddFormMaker'>
              Crear formulario
            </button>
          </div>

        </a-form>
        <CancelFormModal
          v-if='state.showCancelModal'
          :visible='state.showCancelModal'
          :onCancel='toggleShowCancelModal'
          :onOk='handleCancelDynamicForm'
        />
        <DeleteSectionModal
          v-if='state.showDeleteModal'
          :visible='state.showDeleteModal'
          :onCancel='toggleShowDeleteModal'
          :onOk='handleDeleteSection'
        />
      </VerticalFormStyleWrap>

    </FormValidationWrap>
  </div>
</template>

<script>
import { Input } from 'ant-design-vue';
const { TextArea } = Input;
import * as questionTypes from '@/constants/QuestionTypes.js';
import { reactive } from 'vue';
import {FormValidationWrap, VerticalFormStyleWrap} from './Style';
import draggable from 'vuedraggable';
import CancelFormModal  from './CancelFormModal';
import Question from './Question.vue';
import {generateUUID, cloneObject} from '@/utility/utility';
import DeleteSectionModal from './DeleteSectionModal';

const FormMaker = {
  props:{
    eventForm: Object,
    sendForm: null,
  },
  watch: {
    sendForm: function () {
      this.submit();
    },
  },
  name: 'DynamicForm',
  components: { Input, TextArea, FormValidationWrap, VerticalFormStyleWrap, Question, draggable, CancelFormModal,DeleteSectionModal },
  emits: ['getFormValues'],
  async setup(props, {emit}) {

    const copyQuestion = (sectionIndex, questionIndex) => {

      const question =  JSON.parse(JSON.stringify(props.eventForm.dynamicForm.sections[sectionIndex].questions[questionIndex]));
      question.id = `Q${generateUUID()}`;
      question.matrixOptions.rows.forEach(row => row.id = `R${generateUUID()}`);
      question.matrixOptions.cols.forEach(col => col.id = `R${generateUUID()}`);
      if (question.type == questionTypes.matrixTxt) {
        const prevQuestion = props.eventForm.dynamicForm.sections[sectionIndex].questions[questionIndex];
        question.matrixValidations = {};
        for (let rowIndex = 0; rowIndex < prevQuestion.matrixOptions.rows.length; rowIndex++) {
          for (let colIndex = 0; colIndex < prevQuestion.matrixOptions.cols.length; colIndex++) {
            question.matrixValidations[`T_${question.matrixOptions.cols[colIndex].id}_${question.matrixOptions.rows[rowIndex].id}`] = cloneObject(prevQuestion.matrixValidations[`T_${prevQuestion.matrixOptions.cols[colIndex].id}_${prevQuestion.matrixOptions.rows[rowIndex].id}`]);
          }
        }
      }
      props.eventForm.dynamicForm.sections[sectionIndex].questions.push(question);

    }
    const deleteQuestion = (sectionIndex, questionIndex) => {
      props.eventForm.dynamicForm.sections[sectionIndex].questions.splice(questionIndex, 1);

    };
    const addQuestion = (sectionIndex) => {
      const rowID = `R${generateUUID()}`
      const colID = `C${generateUUID()}`
      
      props.eventForm.dynamicForm.sections[sectionIndex].questions.push(
        {
          id: `Q${generateUUID()}`,
          uuid: '',
          validations: {
            required: false,
            dataType: 'string',
            characterType: 'text',
            validationType: 'limits',
          },
          maxFileSize: 10,
          maxFiles: 1,
          matrixValidations: {
            [`T_${colID}_${rowID}`] :{
                dataType: 'text',
                validationType: 'limits',
                min: '',
                max: '',
                format: 'email',
            }
          },
          hasDescription: false,
          hasValidations: true,
          title: '',
          description: '',
          type: questionTypes.shortAnswer,
          options: [
            { id: `O${generateUUID()}`, content: '', label: '', goto: 'next' },
          ],
          matrixOptions: {
            rows: [
              { id: rowID, content: '' },
            ],
            cols: [
              { id: colID, content: '' },
            ],
          },
          goto: false,
          layout: 'vertical',

        },
      )


    };
    const addSection = () => {
      props.eventForm.dynamicForm.sections.push(
        {
          id: `S${generateUUID()}`,
          title: '',
          description: '',
          questions: [
            {
              id: `Q${generateUUID()}`,
              uuid: '',
              validations: {
                required: false,
                dataType: 'string',
                characterType: 'text',
                validationType: 'limits',
              },
              maxFileSize: 10,
              maxFiles: 1,
              matrixValidations: {},
              hasDescription: false,
              hasValidations: true,
              title: '',
              description: '',
              type: questionTypes.shortAnswer,
              options: [
                { id: `O${generateUUID()}`, content: '', label: '', goto: "next" },
              ],
              matrixOptions: {
                rows: [
                  { id: `R${generateUUID()}`, content: '' },
                ],
                cols: [
                  { id: `C${generateUUID()}`, content: '' },
                ],
              },
              goto: false,
              layout: 'vertical',

            },
          ],
        },
      )


    };
    const toggleRequired = (sectionIndex, questionIndex)=>{
      const required = props.eventForm.dynamicForm.sections[sectionIndex].questions[questionIndex].validations.required;
      props.eventForm.dynamicForm.sections[sectionIndex].questions[questionIndex].validations.required = !required;


    }
    const toggleGoTo = (sectionIndex, questionIndex)=>{
      const gotoValue = props.eventForm.dynamicForm.sections[sectionIndex].questions[questionIndex].goto;
      props.eventForm.dynamicForm.sections[sectionIndex]
        .questions[questionIndex]
        .goto = !gotoValue;
    }
    const copySection = (sectionIndex) => {
      const section =  JSON.parse(JSON.stringify(props.eventForm.dynamicForm.sections[sectionIndex]));
      section.id = generateUUID();
      section.id = `S${generateUUID()}`;
      section.questions.forEach(q=>{
        q.id = `Q${generateUUID()}`;
      })
      props.eventForm.dynamicForm.sections.push(section);

    }
    const handleDeleteSection = () => {
      props.eventForm.dynamicForm.sections.splice(state.sectionToDelete, 1);
      state.showDeleteModal = false;
    };

    const isThereAnError = (object) => {
      for (let key in object) {
        if (typeof (object[key]) == "object") {
          if (Array.isArray(object[key])) {
            let isThereError = false
            object[key].forEach(object => {
              if (isThereAnError(object)) {
                isThereError = true
              }
            })
            if (isThereError) {
              return true
            }
          }
          else {
            if (isThereAnError(object[key])) {
              return true
            }
          }
        }
        else if (object[key]) {
          return true
        }
      }
      return false
    }
    const validateForm = () => {
      const newErrors = JSON.parse(JSON.stringify(formState.errors));
      newErrors.sections = props.eventForm.dynamicForm.sections.map(section => {
        let newSection = {}
        newSection.title = !section.title
        newSection.questions = section.questions.map(question => {
          let newQuestion = { validations: {}, matrixValidations: {} }
          newQuestion.title = !question.title
          if (question.type == questionTypes.file) {
            if (question.allowSomeFiles) {
              if (!question.allowedFiles) {
                newQuestion.allowedFiles = true
              }
              else {
                let validator = 0;
                for (let key in question.allowedFiles) {
                  if(question.allowedFiles[key]){
                    validator +=1;
                  }
                }
                if (validator<1) {
                  newQuestion.allowedFiles = true
                }
              }
            }
          }
          if (question.type == questionTypes.cbx || question.type == questionTypes.dropDown || question.type == questionTypes.multipleChoice) {
            newQuestion.noOptions = !question.options.length
            newQuestion.options = question.options.map(option => {
              let newOption = {}
              newOption.content = !option.content
              return newOption
            })
          }
          if (question.type == questionTypes.shortAnswer && question.hasValidations) {
            if(question.validations.validationType){
              if(question.validations.validationType == 'predet'){
                newQuestion.validations.format = !question.validations.format
              }else if(question.validations.validationType == 'limits'){
                if(question.validations.dataType == 'string' || (question.validations.dataType == 'number' && !question.validations?.length)){
                    newQuestion.validations.min = (parseInt(question.validations.min) <= 0 || parseInt(question.validations.min) > parseInt(question.validations.max))
                    newQuestion.validations.max = (parseInt(question.validations.max) <= 0 || parseInt(question.validations.max) < parseInt(question.validations.min))
                }
              }
            }
          }
          if (question.type == questionTypes.largeAnswer && question.hasValidations) {
            newQuestion.validations.min = (parseInt(question.validations.min) <= 0 || parseInt(question.validations.min) > parseInt(question.validations.max))
            newQuestion.validations.max = (parseInt(question.validations.max) <= 0 || parseInt(question.validations.max) < parseInt(question.validations.min))
          }

          if (question.type == questionTypes.matrixTxt && question.hasValidations) {
            question.matrixOptions.cols.map((col) => question.matrixOptions.rows.map((row) => {
              const fieldName = `T_${col.id}_${row.id}`
              if(question.matrixValidations?.[fieldName]?.dataType){
                if(question.matrixValidations?.[fieldName]?.dataType == 'text'){
                  if(question.matrixValidations?.[fieldName]?.validationType == 'predet'){
                    newQuestion.matrixValidations[fieldName] = {format: !question.matrixValidations?.[fieldName]?.format}
                  }else if(question.matrixValidations?.[fieldName]?.validationType == 'limits'){
                    newQuestion.matrixValidations[fieldName] = {
                      min: !question.matrixValidations?.[fieldName]?.min || (parseInt(question.matrixValidations?.[fieldName]?.min) > parseInt(question.matrixValidations?.[fieldName]?.max) || parseInt(question.matrixValidations?.[fieldName]?.min) < 0),
                      max: !question.matrixValidations?.[fieldName]?.max || (parseInt(question.matrixValidations?.[fieldName]?.max) < parseInt(question.matrixValidations?.[fieldName]?.min) || parseInt(question.matrixValidations?.[fieldName]?.max) < 0)
                    }
                  }
                }
              }else{
                newQuestion.matrixValidations[fieldName] = {dataType: true}
              }
            }))
          }
          if (question.type == questionTypes.cbx && question.hasValidations) {
            if(question.validations.validationType){
              if(question.validations.validationType == 'limits'){
                // newQuestion.validations.min = !question.validations?.min || (parseInt(question.validations.max) < parseInt(question.validations.min) || parseInt(question.validations.min) < 0 || parseInt(question.validations.min) > question.options.length)
                // newQuestion.validations.max = !question.validations?.max || (parseInt(question.validations.max) < parseInt(question.validations.min) || parseInt(question.validations.max) < 0 || parseInt(question.validations.max) > question.options.length)
              }else if(question.validations.validationType == 'exact'){
                newQuestion.validations.length = !question.validations?.length
              }
            }else{
              newQuestion.validations.validationType = true;
            }
          }
          if (question.type == questionTypes.cct) {
              newQuestion.cct = !question?.cct?.length;
          }
          return newQuestion;
        });
        return newSection;
      })
      formState.errors = newErrors;

      return (!isThereAnError(newErrors))
    }

    const editingQuestion = reactive({id: null});
    const state = reactive({
      showCancelModal: false,
      showDeleteModal: false,
      sectionToDelete: null
    });

    const formState = reactive({errors: { sections: [] }})

    const submit = () => {
      emit('getFormValues', { is_ok: validateForm(), typeForm: 'dynamicForm' });
    };

    return {
      form: props.eventForm.dynamicForm,
      eventForm: props.eventForm,
      addQuestion,
      deleteQuestion,
      copyQuestion,
      addSection,
      toggleRequired,
      toggleGoTo,
      handleDeleteSection,
      copySection,
      editingQuestion,
      state,
      formState,
      submit
    };
  },
  methods: {
    setEditingQuestion: function(id) {
      this.editingQuestion.id = id;
    },
    toggleShowCancelModal: function(){
      this.state.showCancelModal= !this.state.showCancelModal;
    },
    toggleShowDeleteModal: function(){
      this.state.showDeleteModal= !this.state.showDeleteModal;
    },
    handleCancelDynamicForm: function(){
      this.form =
        {
          id: '',
          updatedQuestions: [],
          sections: [
            {
              id: `S${generateUUID()}`,
              title: '',
              description: '',
              questions: [
                {
                  id: `Q${generateUUID()}`,
                  uuid: '',
                  validations: {
                    required: false,
                    dataType: 'string',
                    characterType: 'text',
                    validationType: 'limits',
                  },
                  maxFileSize: 10,
                  maxFiles: 1,
                  matrixValidations: {},
                  hasDescription: false,
                  hasValidations: true,
                  title: '',
                  description: '',
                  type: questionTypes.shortAnswer,
                  options: [
                    { id: `O${generateUUID()}`, content: '', label: '' },
                  ],
                  matrixOptions: {
                    rows: [
                      { id: `R${generateUUID()}`, content: '' },
                    ],
                    cols: [
                      { id: `C${generateUUID()}`, content: '' },
                    ],
                  },
                  layout: 'vertical',

                },
              ],
            },

          ],
          layout: 'vertical'
        }
      this.eventForm.hasDynamicForm = false;
      this.state.showCancelModal = false;
    },
    handleClickAddFormMaker: function(){
      this.eventForm.hasDynamicForm = true;
    },
    handleDeleteSectionModal: function(indexSection){
      this.state.showDeleteModal= !this.state.showDeleteModal;
      this.state.sectionToDelete= indexSection;

    }
  }
};

export default FormMaker;
</script>
<style scoped lang='sass'>
.btn-add-form
  border-radius: 25px
  background-color: #E8394D
  color: #FFFFFF
  padding: 0.5rem
  border: 1px solid #E8394D

.dynamicForm
  padding: 0px 32px
  background-color: white

  .dynamicForm__sections

    .section--number
      background-color: #51AF2C
      border-radius: 16px 16px 0px 0px
      opacity: 1
      color: white
      width: 176px
      height: 48px
      display: flex
      justify-content: center
      align-content: center
      font-size: 18px
      font-family: Avenir Heavy
      margin-left: 0 !important
      margin-top: 15px
    .section--options
      display: flex
      justify-content: flex-end
      border-bottom: 1px solid #CCCCCC
      margin-bottom: 15px
      span
        width: 1px
        height: 3px
        border-right: 0.5px solid #CCCCCC

    .section--info
      border: 1px solid #D3D3D3
      border-radius: 18px
      padding: 28px 30px
      .pointer
        cursor: pointer

    .section--info--number
      border-radius: 0 18px 18px 18px

    .editing-or-dragg
      border-left: 8px solid #51AF2C


  .dynamicForm__buttons
    margin: 20px 0px
    display: flex
    justify-content: center
    gap: 15px

    .btn-cancel
      border: 1px solid #636668
      color: #636668
      padding: 5px 15px 5px 15px
      background: #FFFFFF
      border-radius: 24px
      width: 144px
      height: 48px

    .btn-submit
      border: 1px solid #3FA7E1
      color: #FFFFFF
      padding: 5px 15px 5px 15px
      background: #3FA7E1
      border-radius: 24px
      width: 232px
      height: 48px

.form-card
  margin-left: -15px
  margin-right: -15px
  margin-top: 2rem
  border-radius: 20px
  padding-top: 1rem
.input-field
  border-radius: 30px

.form-font
  font-family: Nutmeg
  &.title
    font-weight: bold
    padding: 0

.form-subtitle
  font-family: Nutmeg
  font-weight: bold
  font-size: 18px
  margin-top: 1rem
  margin-bottom: 1rem

</style>
