<template>
  <ModalStyled
    :title="title"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="onOk"
    @cancel="onCancel"
    :type="color ? this.type : false"
    :width="width"
    :class="className"
    :footer="null"
  >
    <div class='cancelFormModal'>
      <div class="message-box">
        <p>¿Estás seguro de que quieres cancelar tu formulario?</p>
        <p>Las preguntas se perderán.</p>
      </div>
      <div class="footer">
        <button class='btn-cancel' key="back" @click="onCancel">Cancelar</button>
        <button class='btn-submit' key="submit" @click="onOk">Aceptar</button>
      </div>
    </div>
  </ModalStyled>
</template>

<script>
import { ModalStyled } from '../../../../../../components/modals/styled';
import VueTypes from 'vue-types';

export default {
  name: 'Modal',
  components: {
    ModalStyled,
  },
  props: {
    onCancel: VueTypes.func,
    onOk: VueTypes.func,
    visible: VueTypes.bool.def(false),
    confirmLoading: VueTypes.bool.def(false),
    title: VueTypes.string,
    class: VueTypes.string.def('atbd-modal'),
    type: VueTypes.oneOf(['primary', 'secondary', 'success', 'error', 'danger', 'info', 'white', 'warning']).def(
      'white',
    ),
    footer: VueTypes.oneOf([null]),
    width: VueTypes.number.def(620),
    color: VueTypes.oneOfType([VueTypes.bool, VueTypes.string]).def(false),
  },
  data() {
    return {
      className: this.class,
    };
  },
};
</script>

<style scoped lang='sass'>
.cancelFormModal
  .message-box
    p
      color: #7B868C
      font-family: Avenir
      font-size: 18px
  .footer
    margin-top: 15px
    display: flex
    justify-content: flex-end
    gap: 10px
    .btn-cancel
      border: 1px solid #636668
      color: #636668

      background: #FFFFFF
      border-radius: 24px
      width: 132px
      height: 32px

    .btn-submit
      border: 1px solid #3FA7E1
      color: #FFFFFF
      background: #3FA7E1
      border-radius: 24px
      width: 132px
      height: 32px



</style>
