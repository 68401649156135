<template>
  <a-row :gutter='25' style='margin-bottom: 1rem'>
    <a-col :xxl='12' :lg='12' :xs='24'>
      <a-row :gutter='25'  v-for="(row, index) in matrixOptions.rows" :key='row.id'>
        <a-col :xxl='24' :lg='24' :xs='24'>
          <a-form-item
            ref="content"
            name="content"
            class="title-normal"
            :validateStatus="errors && errors?.options && errors?.options[index]?.content ? 'error' : ''"
            :help="errors && errors?.options && errors?.options[index]?.content ? 'Este campo es requerido' : ''"
          >
            <a-input size="small" v-model:value="row.content" class="form-input input-addon" :placeholder="`Fila ${index+1}`">
              <template #addonAfter>
                <img
                  :src="require(`@/static/img_events/SEJ_Eventos_eliminar opcion.svg`)"
                  alt="logo" width="14" height="14"
                  class="pointer"
                  @click="removeRow(index)"
                />
              </template>
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
          <a-button class="btn-fill-add mb-2" @click="addRow()">Agregar otra fila</a-button>
      </a-row>
    </a-col>
    <a-col :xxl='12' :lg='12' :xs='24'>
      <a-row :gutter='25'  v-for="(col, indexCol) in matrixOptions.cols" :key='col.id'>
        <a-col :xxl='24' :lg='24' :xs='24'>
          <a-form-item
            ref="content"
            name="content"
            class="title-normal"
            :validateStatus="errors && errors?.options && errors?.options[indexCol]?.content ? 'error' : ''"
            :help="errors && errors?.options && errors?.options[indexCol]?.content ? 'Este campo es requerido' : ''"
          >
            <a-input size="small" v-model:value="col.content" class="form-input input-addon" :placeholder="`Columna ${indexCol+1}`">
              <template #addonAfter>
                <img
                  :src="require(`@/static/img_events/SEJ_Eventos_eliminar opcion.svg`)"
                  alt="logo" width="14" height="14"
                  class="pointer"
                  @click="removeCol(indexCol)"
                />
              </template>
            </a-input>
          </a-form-item>
        </a-col>

      </a-row>
      <a-row>

          <a-button class="btn-fill-add" @click="addCol()">Agregar otra columna</a-button>

      </a-row>
    </a-col>

  </a-row>
</template>

<script>
import { generateUUID } from '@/utility/utility';

const TextMatrix = {
  name: 'TextMatrix',
  props: {
    matrixOptions: Object,
    errors: Object,
    validations: Object,
  },
  methods: {
    addRow(content=''){
      const rowId = `R${generateUUID()}`;
      this.matrixOptions.rows.push({id: rowId, content, })
      this.handleValidations();
    },
    addCol(content = ''){
      const colId = `C${generateUUID()}`;
      this.matrixOptions.cols.push({id: colId, content, })
      this.handleValidations();
    },
    removeCol(index){
      this.matrixOptions.cols.splice(index, 1);

    },
    removeRow(index){
      this.matrixOptions.rows.splice(index, 1);
    },
    handleValidations(){
      this.matrixOptions.cols.map((col)=>{
        this.matrixOptions.rows.map((row)=>{
          const fieldName = `T_${col.id}_${row.id}`
          if(this.validations[fieldName]){
             return
          }
          this.validations[fieldName] ={
            dataType: 'text',
            validationType: 'limits',
            min: '',
            max: '',
            format: 'email',
          }
        })
      })
    }

  }
};

export default TextMatrix;
</script>
<style scoped>
input {
  border-radius: 20px;
}
.form-input {
  border-radius: 20px;

}
.btn-fill-add{
  border: 1px solid #e70e4c;
  color: #ffffff;
  padding: 5px 13px 5px 13px;
  background: #e70e4c;
  border-radius: 24px;
  font-family: Nutmeg;
}
.text-primary {
  color: #e70e4c;
  font-family: 'Nutmeg';
}
.pointer {
  cursor: pointer;
}
</style>
<style>
.form-input  input {
  border-radius: 20px;
}

.input-addon input {
  border-right: 0;
  height: 40px;
}

.form-input span.ant-input-group-addon {
  background: transparent;
  border-radius: 0 20px 20px 0;
}

</style>
